import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';

export type ConfirmDialogProps = {
  cancelLabel?: string;
  confirmLabel?: string;
  isProcessing?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  title: string;
  warningMessage?: string;
};

export const ConfirmDialog = ({
  cancelLabel = 'Cancel',
  confirmLabel = 'Yes',
  isProcessing = false,
  onCancel,
  onConfirm,
  title,
  warningMessage,
}: ConfirmDialogProps) => (
  <Modal
    closeOnOverlayClick={false}
    isCentered
    isOpen
    onClose={onCancel || (() => {})}
  >
    <ModalOverlay />

    <ModalContent>
      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        <Stack spacing={4}>
          {warningMessage ? <Text as="p">{warningMessage}</Text> : null}
        </Stack>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button onClick={onCancel}>{cancelLabel}</Button>

          <Button
            colorScheme="red"
            fontWeight="medium"
            isDisabled={isProcessing}
            isLoading={isProcessing}
            onClick={onConfirm}
          >
            {confirmLabel}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
