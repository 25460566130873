export enum AsyncTaskStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  WAITING_FOR_INPUT = 'WAITING_FOR_INPUT',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  REQUEUING = 'REQUEUING',
  RETRYING = 'RETRYING',
}

export const COMPLETED_TASK_STATUSES = [
  AsyncTaskStatus.COMPLETE,
  AsyncTaskStatus.ERROR,
];

export enum AsyncTaskType {
  CONNECT_ACCOUNT = 'CONNECT_ACCOUNT',
  CONTACT_ENRICHMENT = 'CONTACT_ENRICHMENT',
  CONTACT_PERSONALIZE = 'CONTACT_PERSONALIZE',
  CONTACT_PROFILE_ENRICHMENT = 'CONTACT_PROFILE_ENRICHMENT',
  EXTERNAL_CONTACT_SYNC = 'EXTERNAL_CONTACT_SYNC',
  GET_CAMPAIGN_DATA = 'GET_CAMPAIGN_DATA',
  IMPORT_CONTACTS = 'IMPORT_CONTACTS',
  LINKEDIN_SCHEDULED_SEND = 'LINKEDIN_SCHEDULED_SEND',
  LINKEDIN_SEND = 'LINKEDIN_SEND',
}

export interface AsyncTask {
  customerId?: number;
  userId?: number;
  asyncTaskId?: number;
  async_task_id: number;
  status: AsyncTaskStatus;
  type: AsyncTaskType;
  taskData: any;
  dateUpdated?: Date;
}
