import type { ComponentType, FC, JSX } from 'react';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useAuthStore } from '@/stores/AuthStore';
import { useCurrentUser } from '@/stores/CurrentUserStore';
import { UserStatus } from 'types/User';

export const publicPaths = [
  '/login',
  '/signup',
  '/verifyEmail',
  '/forgotPassword',
  '/success',
  '/home',
  '/privacy',
  '/terms',
];

const skipVerifyEmailPaths = ['/login', '/verifyEmail', '/forgotPassword'];

export default function withAuthRedirect<P extends JSX.IntrinsicAttributes>(
  WrappedComponent: ComponentType<P>
): FC<P> {
  return function AuthRedirect(props: P) {
    const { isSignedIn } = useAuthStore();
    const { user } = useCurrentUser();

    const router = useRouter();
    const searchParams = useSearchParams();

    const source = searchParams.get('source');

    const isPublicPage = publicPaths.includes(router.pathname);
    const skipVerifyEmailRedirect = skipVerifyEmailPaths.includes(
      router.pathname
    );

    const doRedirects = () => {
      if (user?.isEmailVerified === false) {
        if (!skipVerifyEmailRedirect) {
          router.push('/verifyEmail');
          return true;
        }

        return false;
      }

      if (!isSignedIn) {
        if (!isPublicPage) {
          router.push('/login');
          return true;
        }

        return false;
      }

      if (
        ['/', '/login', '/verifyEmail', '/signup'].includes(router.pathname)
      ) {
        if (source === 'chrome-ext') {
          router.push('/success');
          return true;
        }

        router.push('/campaigns');
        return true;
      }

      if (router.pathname === '/campaigns' && user?.status === UserStatus.NEW) {
        router.push('/welcome');
        return true;
      }

      return false;
    };

    if (doRedirects()) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
}
