import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useGetCustomer } from '@/hooks/useCustomer';
import { useGetUser } from '@/hooks/useUser';
import { ReadyFlags, setReadyFlag } from '@/stores/AppReadyStore';
import { setAuth, signOut } from '@/stores/AuthStore';
import { setCurrentCustomer } from '@/stores/CurrentCustomerStore';
import { setCurrentUser } from '@/stores/CurrentUserStore';
import { publicPaths } from 'src/client/hoc/withAuthRedirect';
import { UserRole } from 'types/User';

export const SessionWrapper = ({ children }: PropsWithChildren) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const isPublicPage = publicPaths.includes(router.pathname);

  const {
    data: user,
    error: userError,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
  } = useGetUser({ enabled: !isPublicPage, userId: 'current' });

  const {
    data: customer,
    error: customerError,
    isLoading: isCustomerLoading,
    isFetching: isCustomerFetching,
  } = useGetCustomer({ enabled: !isPublicPage, customerId: 'current' });

  useEffect(() => {
    if (isPublicPage || !isUserLoading || !isCustomerLoading) {
      setReadyFlag(ReadyFlags.session);
    }
  }, [isCustomerLoading, isPublicPage, isUserLoading]);

  if (isPublicPage) {
    return <>{children}</>;
  }

  if (
    ((isUserLoading || isUserFetching) && !user) ||
    ((isCustomerLoading || isCustomerFetching) && !customer)
  ) {
    return null;
  }

  if (userError || customerError) {
    console.log('signOut - SessionWrapper');

    if (userError) {
      Sentry.captureMessage(`SessionWrapper error (user): ${userError}`);
    }

    if (customerError) {
      Sentry.captureMessage(
        `SessionWrapper error: (customer): ${customerError}`
      );
    }

    queryClient.clear();

    signOut();
    router.push('/login');

    return null;
  }

  if (!isPublicPage && (!user || !customer)) {
    if (!user) {
      Sentry.captureMessage(`SessionWrapper nullcheck (user): user is null`);
    }

    if (!customer) {
      Sentry.captureMessage(
        `SessionWrapper nullcheck: (customer): customer is null`
      );
    }

    router.push('/login');
    return null;
  }

  if (user) {
    Sentry.setUser({ id: user.userId.toString(), email: user.email });

    setAuth({
      isAdminUser: user.role === UserRole.ADMIN,
      isSysUser: user.isSysUser,
      isStripeUser: user.isStripeUser || false,
    });

    setCurrentUser(user);
  }

  if (customer) {
    setCurrentCustomer(customer);
  }

  return <>{children}</>;
};
